const useSearchParam = (param) => {
  try {
    const search = window.location.search;
    const regex = new RegExp(`${param}=`);
    let extracted = search && search.match(regex) ? search.replace(regex, '') : null;
    extracted = extracted.replace(/\?/g, '');
    extracted = extracted.replace(/\&/g, '');
    if (!extracted) return null;
    return extracted;    
  } catch (error) {
    console.log(error);
  }
};

export default useSearchParam;
