import React, { useEffect, useState } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Hero from '../components/partials/institution/Hero';
import Info from '../components/partials/institution/Info';
import Cta from '../components/partials/institution/Cta';
import Gallery from '../components/partials/institution/Gallery';
import Testimonials from '../components/partials/institution/Testimonials';

import useSearchParam from '../components/hooks/useSearchParam';
import useInstitutions from '../components/hooks/useInstitutions';

const Institution = (props) => {

  const { location = {} } = props;
  const { state = {} } = location;
  const { institution } = state || {};

  const institutionId = useSearchParam('uid');

  const [data, setData] = useState(institution || {});
  const [{ fetch }] = useInstitutions();

  useEffect(() => {
    if (!institution) fetch(institutionId).then((response) => setData(response[0]));
  }, [institution]);

  return (
    <div className="is-inner-page">
      <Layout>

        <SEO title="Detalhes da ONG" />

        <Hero {...data} />
        <Info {...data} />
        <Cta {...data} />
        <Gallery {...data} />
        <Testimonials {...data} />

      </Layout>
    </div>
  );

};

export default Institution;
