import React from 'react';
import { Link } from 'gatsby';

const Cta = (props) => {

  let maxDonations = null;

  if (props.target > 0) {
    maxDonations = props.donations >= props.target;
  }

  return (
    <section className="section has-title-overlaid">
      <span className="title-overlaid">Escolha essa ONG</span>
      <div className="container">
        <div className="wrapper is-flex">
          <h1 className="title is-xlarge is-uppercase is-alternative is-700">Escolha essa ONG</h1>
          <Link
            to={!maxDonations ? `cadastro?uid=${props.id}` : 'instituicoes'}
            state={{ institution: props }}
            disabled={maxDonations}
            className="button">
            <span>{!maxDonations ? 'Selecionar ONG' : 'Doações encerradas'}</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Cta;