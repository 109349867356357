import React, { useEffect } from 'react';

import Swiper from 'swiper';
import useStorage from '../../hooks/useStorage';

const Gallery = ({ picturesFolder, name }) => {

  const [getImages, { response }] = useStorage(picturesFolder);

  useEffect(() => {
    new Swiper ('.swiper-container-gallery', {
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1150: {
          slidesPerView: 2,
          slidesPerColumn: 1,
        },
        668: {
          slidesPerView: 1,
          slidesPerColumn: 1,
        },
        320: {
          slidesPerView: 1,
          slidesPerColumn: 1,
        }
      }
    });
  }, [response]);

  useEffect(() => {
    if (picturesFolder) getImages();
  }, [picturesFolder]);

  return (
    <section
      className="section has-dots no-padding-top no-padding-bottom has-light-bg"
      style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-bg.svg')})` }}>
      <div className="v-space-xxlg"></div>
      <div className="container">
        <header className="section-header isclsr">
          <h1 className="title">Galeria de fotos</h1>
        </header>
        <div className="swiper-container swiper-container-gallery">
          <div className="swiper-wrapper">
            {response.map((pic, index) => (
              <div className="swiper-slide" key={index}>
                <figure
                  className="image is-gallery-item"
                  style={{ backgroundImage: `url(${pic.trim()})` }}>
                  <img src={pic.trim()} alt={name + ' imagem ' + (index + 1)} />
                </figure>
              </div>
            ))}
          </div>
          <div className="swiper-pagination" />
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;