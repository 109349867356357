import React from 'react';

const Testimonials = ({ testimonials }) => {
  if (!testimonials) return null;
  return (
    <section
      className="section has-dots"
      style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-bg.svg')})` }}>
      <div className="container">
        <header className="section-header isclsr">
          <h1 className="title">Conheça um pouco mais a ONG</h1>
        </header>
        <div className="yt-video f-h-center">
          <iframe
            width="560"
            height="315"
            src={'https://www.youtube.com/embed/' + testimonials}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </section>
  );
};

export default Testimonials;