import React from 'react';

import IMask from 'imask';

const mask = IMask.createMask({
  mask: 'num',
  blocks: {
    num: {
      mask: Number,
      scale: 0,
      thousandsSeparator: '.',
      min: 0,
      max: 10000000,
    },
  },
});

const Info = (props) => {

  const {
    name,
    description,
    donations = 0,
    children = 0
  } = props;

  return (
    <section
      className="section has-dots no-padding-bottom"
      style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-bg.svg')})` }}>

      <div className="container">

        <header className="section-header">

          <h1 className="title is-xlarge-m">
            {name}
          </h1>
          <div className="v-space-lg"></div>
          <p className="text">
            {description}
          </p>
          <div className="v-space-lg"></div>

          <div className="columns is-multiline f-h-center">
            <div className="column is-half-desktop is-center-mobile">
              <div className="media">
                <div className="media-left">
                  <figure
                    className="image is-icon"
                    style={{ backgroundImage: `url(${require('../../../images/icons/icon-tshirt-dark.svg')})` }}>
                    <img src={require('../../../images/icons/icon-tshirt-dark.svg')} alt="Image Placeholder" />
                  </figure>
                </div>
                <div className="media-content">
                  <p className="text is-xlarge is-700">
                    {mask.resolve(donations + '')}
                  </p>
                  <p className="text is-size-4 is-uppercase is-300">
                    Doações
                  </p>
                  <span className="line-content"></span>
                </div>
              </div>
            </div>
            <div className="column is-half-desktop is-center-mobile">
              <div className="media">
                <div className="media-left">
                  <figure
                    className="image is-icon"
                    style={{ backgroundImage: `url(${require('../../../images/icons/icon-kids-dark.svg')})` }}>
                    <img src={require('../../../images/icons/icon-kids-dark.svg')} alt="Image Placeholder" />
                  </figure>
                </div>
                <div className="media-content">
                  <p className="text is-xlarge is-700">
                    {mask.resolve(children + '')}
                  </p>
                  <p className="text is-size-4 is-uppercase is-300">
                    Crianças atendidas
                  </p>
                  <span className="line-content"></span>
                </div>
              </div>
            </div>
          </div>

        </header>
      </div>
    </section>
  );
};

export default Info;